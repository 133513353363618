import { HTMLAttributes } from "react";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { Tooltip } from "../tooltip/Tooltip";

interface Props extends HTMLAttributes<HTMLDivElement> {
  number: number;
  children: JSX.Element[] | JSX.Element;
  color: CssVariablesType;
  colorText?: CssVariablesType;
  right?: string;
  left?: string;
  top?: string;
  bottom?: string;
  maxCount?: number;
  tooltip?: string;
}

export default function IndicatorCount({
  children,
  color = "text",
  colorText = "white",
  number = 0,
  maxCount = null,
  tooltip = "",
  right = "-30%",
  left = "unset",
  top = "unset",
  bottom = "-20%",
  ...props
}: Props) {
  return (
    <div className="position relative" style={{ width: "fit-content" }}>
      {children}

      <Tooltip
        legend={maxCount && maxCount < number ? number.toString() + " " + tooltip : ""}
        style={{
          background: "var(--color-" + color + ")",
          zIndex: 4,
          borderRadius: "300px",
          padding: "6px",
          minWidth: "25px",
          textAlign: "center",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          right,
          bottom,
          left,
          top,
          userSelect: "none",
        }}
      >
        <span className="fs-80 fw-700" style={{ color: "var(--color-" + colorText + ")" }} {...props}>
          {maxCount ? <>{maxCount < number ? maxCount + "+" : number}</> : number}
        </span>
      </Tooltip>
    </div>
  );
}
